@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./sass/variables/variables.scss";

.App {
  text-align: center;
  background-color: #06011a;
  height: 100vh;
  overflow-x: hidden;
  font-family: Poppins;
}

.body {
  background-color: #06011a;
}

.drawer-toggle ~ .drawer-side {
  grid-column-start: 1;
  grid-row-start: 1;
  display: grid;
  max-height: 100%;
}

.bottom-10 {
  top: 90vh;
}

.hero-body {
  width: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  background: transparent;
  border: none;
  outline: none;
}

.primary-button {
  padding: 10px 17px;
  background: rgba(247, 250, 255, 0.1);
  border: 1px solid #f7faff;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.secondary-button {
  padding: 10px 17px;
  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    #fd004e;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
}


    .unsubscribe-button {
      padding: 10px 17px;
      background: linear-gradient(
          273.47deg,
          rgba(255, 255, 255, 0) -1.96%,
          #00ffcc 104.81%
        ),
        #00cc66;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      border: none;
      outline: none;
      transition: background 0.3s ease-in-out;
    }
    
    .unsubscribe-button:hover {
      background: linear-gradient(
          273.47deg,
          rgba(255, 255, 255, 0) -1.96%,
          #00cc99 104.81%
        ),
        #00994d;
    }
    
  
  

label {
  cursor: pointer;
}

.example {
  overflow-y: scroll;
  height: 100%;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*  */

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var (--swiper-pagination-bullet-horizontal-gap, 4px);
  background: white !important;
}

/*  */
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
}
.textrgbcolor {
  background: linear-gradient(
      290.19deg,
      rgba(255, 255, 255, 0) 26.59%,
      #2d1582 112.22%
    ),
    #fd004e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.button-background {
  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    linear-gradient(0deg, #fd004e, #fd004e), #1b1c2d;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
}

#toast-message {
  background: #1b1c2d;
  border: 1px solid #363636;
  border-radius: 5px;
  color: #fff;
}
.drawer {
  height: 100%;
}

// Speech Bubbles

.speech-bubble-left {
  border: 0.5px solid #ffffff20;
  background-color: #2d158240;
  height: auto;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 24px;
  border-bottom-left-radius: 0px;
  width: fit-content;

}

.speech-bubble-right {
  border: 0.5px solid #ffffff20;
  background-color: #06011a80;
  height: auto;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 24px;
  border-bottom-right-radius: 0px;
  width: fit-content;


}

.speech-bubble {
  border: 0.5px solid #ffffff50;
  border-radius: 16px;
}