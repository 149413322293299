.card {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 8px 0px -3px #292e52;
  border-radius: 14px;
}

.card-button {
  border-top: 2px solid #1a1d1f;
}

.discount-card {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 14px;
}

.add-image-card {
  background: #1b1c2d;
  border-radius: 4.42453px;
  width: 208px;
  height: 150px;
  // height: auto;
}
.add-image-card2 {
  background: #1b1c2d;
  border-radius: 4.42453px;
  width: 208px;
  min-height: 150px;
  // height: auto;
}
.add-address-card {
  background: #1b1c2d;
  border-radius: 4.42453px;
  width: auto;
  height: 160px;
  // height: auto;
}
.add-address-card:hover {
  background: #13141f;
}

.add-image-card:hover {
  background: #13141f;
}
.add-image-card-2 {
  background: #1b1c2d;
  border-radius: 4.42453px;
  width: 208px;
  min-height: 180px;
  // height: auto;
}
@media only screen and (max-width: 480px) { 
  .add-image-card {
    background: #1b1c2d;
    border-radius: 4.42453px;
    max-width: 100%;
    height: 150px;
    // height: auto;
  }
  .add-image-card2 {
    background: #1b1c2d;
    border-radius: 4.42453px;
    width: 100%;
    height: 150px;
    // height: auto;
  }
  .add-address-card {
    background: #1b1c2d;
    border-radius: 4.42453px;
    width: 100%;
    height: 150px;
    // height: auto;
  }
}
